<template>
  <el-row type="flex" justify="center" class="caseShows" :class="{'isEn':lang == 'en'}">
    <el-col :xs="8" :sm="6" :md="1" :lg="1" :xl="1">
        <div class="atitle">
          <span>{{cname}}</span>
        </div>
    </el-col>
    <!-- <div class="imgBox"><img :src="bgImg" alt=""></div> -->
   </el-row>
</template>
<script>
export default {
  name: "DemoTitle",
  props: ["cname"],
  data() {
    return {
      bgImg: require(`assets/images/${this.cname}.png`),
    };
  },
  computed: {
    lang() {
      return this.$store.state.lang;
    },
  },
};
</script>
<style lang="scss" scoped>
// .isEn {
//   .atitle {
//     margin-left: 50px;
//   }
// }
.caseShows {
  margin: 50px 0;
  .atitle {
    text-align: center;
    width: 120px;
    margin-left: -30px;
    span {
      font-size: 25px;
      font-weight: bold;
      padding-bottom: 5px;
      border-bottom: 2px solid #ed0014;
    }
  }
  // .imgBox {
  //   height: 40px;
  //   position: absolute;
  //   object-fit: scale-down;
  //   border: 1px dashed;
  //   transform: translatey(-58%);
  // }
}
@media screen and (max-width: 1000px) {
  .caseShows {
    margin: 15px 0 15px 50px;
  }
}
</style>

