<template>
    <div class="mask" v-loading="loading" element-loading-text="Loading..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0)"></div>
</template>
<script>
export default {
  name: "Loading",
  props: ["loading"],
};
</script>
<style lang="scss" scpoed>
.mask {
  width: 100%;
  height: 700px;
  margin-bottom: 500px;
}
</style>