<template>
    <div class="content" :class="{'mobile':isMobile}">
        <div class="container1">
            <img :src="caseMsg.content1.imgUrl" alt="">
            <div class="msg1">
                <h2 class="msg1Title text_ov_el">{{caseMsg.content1.title}}</h2>
                <p>{{caseMsg.content1.msg}}</p>
            </div>
        </div>
        <div class="bottomContainer">
          <div class="container2" v-if="caseMsg.content2.title">
            <div class="imgContainer">
              <img :src="caseMsg.content2.imgUrl" alt="">
              <div class="title text_ov_el">{{caseMsg.content2.title}}</div>
              <p class="text_l3">{{caseMsg.content2.msg}}</p>
              <a href="javascript:;" @click="jumpPage('CaseShows', pindex)">了解更多</a>
            </div>
          </div>
          <div class="container3" v-if="caseMsg.content3.title">
            <div class="imgContainer">
              <img :src="caseMsg.content3.imgUrl" alt="">
              <div class="title text_ov_el">{{caseMsg.content3.title}}</div>
              <p class="text_l3">{{caseMsg.content3.msg}}</p>
              <!-- <p><a href="javascript:;">了解更多</a></p> -->
            </div>        
          </div>
          <div class="container4" v-if="!isMobile && caseMsg.content4.title">
            <div class="imgContainer">
              <img :src="caseMsg.content4.imgUrl" alt="">
              <div class="title text_ov_el">{{caseMsg.content4.title}}</div>
              <p class="text_l3">{{caseMsg.content4.msg}}</p>
              <!-- <p><a href="javascript:;">了解更多</a></p> -->
              </div>
            </div>
          </div>
    </div>
</template>
<script>
import Bus from "@/utils/bus.js";

export default {
  name: "CaseContainer",
  props: ["caseMsg", "isMobile", "pindex"],
  computed: {
    eq() {
      return this.$store.state.eq;
    },
  },
  methods: {
    jumpPage(routerName, index) {
      if (this.eq == "m") {
        this.$router.push({
          name: "M" + routerName,
          params: {
            index,
          },
        });
        return;
      }
      this.$router.push({
        name: routerName,
        params: {
          index,
        },
      });
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/mixin.scss";
@import "../assets/scss/global.scss";
.container1 {
  height: 307px;
  margin: 36px 0;
  padding-bottom: 25px;
  @include flex("center", "center");
  border-bottom: 1px dashed #fff;
  img {
    object-fit: cover;
    border-radius: 5px;
    width: 65%;
    height: 100%;
    margin-right: 50px;
  }

  h2 {
    font-size: 18px;
    color: #fff;
    padding: 15px 0;
  }

  .msg1 {
    width: 50%;
  }
}
.bottomContainer {
  @include flex("center", "center");
  .container2,
  .container3,
  .container4 {
    width: 313.4px;
  }
  a {
    display: block;
    width: 70px;
    height: 25px;
    border: 1px solid #fff;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0);
    text-align: center;
    font-size: 12px;
    color: #fff;
    text-decoration: none;
    padding-top: 2px;
    margin-top: 15px;
  }
  > div {
    margin: 0 5px;
    overflow: hidden;
    .imgContainer {
      height: 50%;
      margin-bottom: 20px;
      img {
        height: 191px;
        border-radius: 5px;
        object-fit: cover;
      }
      .title {
        height: 42px;
        line-height: 36px;
        font-size: 18px;
        position: relative;
        text-align: center;
        transform: translatey(-100%);
        padding: 4px 0;
        border-radius: 0 0 5px 5px;
        background-color: rgba($color: #000000, $alpha: 0.7);
      }
    }
    p {
      height: 63px;
      margin-top: -20px;
      font-size: 14px;
    }
  }
}
.mobile {
  .container1 {
    height: 266px;
    flex-flow: column;
    padding: 0 15px 15px 15px;
    margin: 10px 0;
    img {
      width: 100%;
      height: 174px;
    }
    h2 {
      margin: 0;
      padding: 0;
    }
    p {
      padding: 0 5px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .msg1 {
      width: 100%;
    }
  }
  .bottomContainer {
    margin: 0 5px;
    .container2,
    .container3,
    .container4 {
      width: 200px;
      .title {
        height: 30px;
        line-height: 24px;
        font-size: 15px;
      }
    }
    .imgContainer {
      text-align: center;
      margin-bottom: 0px;
      img {
        height: 102px;
      }
    }
    p {
      height: 40px;
      margin: -25px 0 0px 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  .msg1Title {
    padding: 5px !important;
    margin: 10px 0 5px 0;
  }
}
</style>
